import React from 'react'
import LayoutGlobal from '../components/LayoutGlobal'
import Seo from '../components/Seo'
import { graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

export default function Kwalifikacje({ data }) {

  const cv_course = data.cv_course.nodes
  const cv_school = data.cv_school.nodes
  const cv_intern = data.cv_intern.nodes
  const cv_learn = data.cv_learn.nodes

  return (
    <LayoutGlobal>

      <Seo 
        title="Kwalifikacje"
        description="Poznaj moje kwalifikacje zawodowe. Edukacja na poziomie akademickim. Poszerzanie wiedzy na specjalistycznych kursach. Praktyki w różnorodnych placówkach medycznych oraz sportowych. Doświadczenie z szerokim wachlarzem pacjentów."
        keywords="fizjoterapeuta, trener personalny"
        url="https://www.fizjopersonalny.pl/kwalifikacje"
      />
      <section aria-label='Sekcja wprowadzająca' className='cv_box_intro'>
        <h1>Kwalifikacje</h1>
          <a href={data.cv_pwzf.childImageSharp.fixed.src} target="_blank" rel="noreferrer" aria-label="Skan dokumentu" title="Prawo wykonywania zawodu fizjoterapeuty">
            <StaticImage src="../images/cv/pwzf.jpg" alt="Skan dokumentu" title="Prawo wykonywania zawodu fizjoterapeuty"/>
          </a>
          <p>Poniżej znajdziesz pełen wykaz moich kwalifikacji zawodowych. Pierwszy element to wykształcenie akademickie. Następnie zrealiowane przeze mnie kursy specjalistyczne. Dalej dowiesz się, gdzie zdobywałem wiedzę praktyczną. Ostatni element to doświadczenie dydaktyczne.</p>
          <br />
          <p>Kliknij na miniaturkę skanu dokumentu, aby otworzyć obraz w większej rozdzielczości.</p>
      </section>

      <section aria-label='Menu pomocnicze' className='sticky_menu'>
        <Link to="#wyksztalcenie-akademickie" title="Wykształcenie akademickie">wykształcenie akademickie</Link>
        <Link to="#kursy-specjalistyczne" title="Kursy specjalistyczne">kursy specjalistyczne</Link>
        <Link to="#doswiadczenie-zawodowe" title="Doświadczenie zawodowe">doświadczenie zawodowe</Link>
        <Link to="#dydaktyka" title="Dydaktyka">dydaktyka</Link>
      </section>

      <section aria-label='Wykształcenie akademickie' className='cv_box' id="wyksztalcenie-akademickie">
        <h2>Wykształcenie akademickie</h2>
        <div>
          <ul>
            {cv_school.map((cv_school, index) => (

              <li key={index}>

                <div>
                  <h3>{cv_school.frontmatter.cv_degree}</h3>
                  <span>
                    {cv_school.frontmatter.cv_academy}<br />
                    {cv_school.frontmatter.cv_department}<br />
                    {cv_school.frontmatter.cv_period}
                  </span>
                </div>

                <div>
                  <a 
                    href={cv_school.frontmatter.cv_img.childImageSharp.original.src}
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={cv_school.frontmatter.cv_img_alt}
                    title={cv_school.frontmatter.cv_img_title}
                  >
                    <GatsbyImage
                      image={cv_school.frontmatter.cv_img.childImageSharp.gatsbyImageData}
                      alt={cv_school.frontmatter.cv_img_alt}
                      title={cv_school.frontmatter.cv_img_title}
                    />
                  </a>
                </div>

              </li>

            ))}
          </ul>
        </div>
      </section>

      <section aria-label='Kursy specjalistyczne' className='cv_box' id="kursy-specjalistyczne">
        <h2>Kursy specjalistyczne</h2>
        <div>
          <ul>
            {cv_course.map((cv_course, index) => (

              <li key={index}>

                <div>
                  <h3>{cv_course.frontmatter.cv_name}</h3>
                  <span>
                    {cv_course.frontmatter.cv_company}<br />
                    {cv_course.frontmatter.cv_tutor}<br />
                    {cv_course.frontmatter.cv_place}, {cv_course.frontmatter.cv_date}
                  </span>
                </div>

                <div>
                  <a 
                    href={cv_course.frontmatter.cv_img.childImageSharp.original.src}
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={cv_course.frontmatter.cv_img_alt}
                    title={cv_course.frontmatter.cv_img_title}
                  >
                    <GatsbyImage
                      image={cv_course.frontmatter.cv_img.childImageSharp.gatsbyImageData}
                      alt={cv_course.frontmatter.cv_img_alt}
                      title={cv_course.frontmatter.cv_img_title}
                    />
                  </a>
                </div>

              </li>

            ))}
          </ul>
        </div>
      </section>

      <section aria-label='Doświadczenie zawodowe' className='cv_box' id="doswiadczenie-zawodowe">
        <h2>Doświadczenie zawodowe</h2>
        <div>
          <ul className='cv_intern'>
            {cv_intern.map((cv_intern, index) => (

              <li key={index}>

                <div>
                  <h3>{cv_intern.frontmatter.cv_name}</h3>
                  <span>
                    {cv_intern.frontmatter.cv_short}<br />
                    {cv_intern.frontmatter.cv_place}, {cv_intern.frontmatter.cv_date}
                  </span>
                </div>

              </li>

            ))}
          </ul>
        </div>
      </section>

      <section aria-label='Dydaktyka' className='cv_box' id="dydaktyka">
        <h2>Dydaktyka</h2>
        <div>
          <ul className='cv_learn'>
            {cv_learn.map((cv_learn, index) => (

              <li key={index}>

                <div>
                  <h3>{cv_learn.frontmatter.cv_name}</h3>
                  <span>
                    {cv_learn.frontmatter.cv_short}<br />
                    {cv_learn.frontmatter.cv_place}, {cv_learn.frontmatter.cv_date}
                  </span>
                </div>

                <div>
                  <a 
                    href={cv_learn.frontmatter.cv_slug}
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={cv_learn.frontmatter.cv_img_alt}
                    title={cv_learn.frontmatter.cv_img_title}
                  >
                    <GatsbyImage
                      image={cv_learn.frontmatter.cv_img.childImageSharp.gatsbyImageData}
                      alt={cv_learn.frontmatter.cv_img_alt}
                      title={cv_learn.frontmatter.cv_img_title}
                    />
                  </a>
                </div>

              </li>

            ))}
          </ul>
        </div>
      </section>

    </LayoutGlobal>
  )
}

export const query = graphql`
query CV {
  cv_pwzf: file(relativePath: {eq: "cv/pwzf.jpg"}) {
    relativePath
    childImageSharp {
      fixed(height: 595, width: 842) {
        src
      }
    }
  }
  cv_school: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(cv)/"}, frontmatter: {cv_id: {eq: "school"}}}
    sort: {order: DESC, fields: frontmatter___cv_year}
  ) {
    nodes {
      frontmatter {
        cv_degree
        cv_academy
        cv_department
        cv_period
        cv_year
        cv_img_alt
        cv_img_title
        cv_img {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
            original {
              src
            }
          }
        }
      }
    }
  }
  cv_course: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(cv)/"}, frontmatter: {cv_id: {eq: "course"}}}
    sort: {order: DESC, fields: frontmatter___cv_day}
  ) {
    nodes {
      frontmatter {
        cv_company
        cv_date
        cv_day(formatString: "DD.MM.YYYY")
        cv_name
        cv_place
        cv_tutor
        cv_img_alt
        cv_img_title
        cv_img {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
            original {
              src
            }
          }
        }
      }
    }
  }
  cv_intern: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(cv)/"}, frontmatter: {cv_id: {eq: "intern"}}}
    sort: {order: DESC, fields: frontmatter___cv_day}
  ) {
    nodes {
      frontmatter {
        cv_name
        cv_place
        cv_short
        cv_date
        cv_day(formatString: "DD.MM.YYYY")
        cv_name
      }
    }
  }
  cv_learn: allMarkdownRemark(
    filter: {fileAbsolutePath: {regex: "/(cv)/"}, frontmatter: {cv_id: {eq: "learn"}}}
    sort: {order: DESC, fields: frontmatter___cv_day}
  ) {
    nodes {
      frontmatter {
        cv_name
        cv_place
        cv_short
        cv_date
        cv_day(formatString: "DD.MM.YYYY")
        cv_name
        cv_slug
        cv_img_alt
        cv_img_title
        cv_img {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
            original {
              src
            }
          }
        }
      }
    }
  }
}
`